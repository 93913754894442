import React from "react";

import async from "./components/Async";

// Layouts
import HomeLayout from "./layouts/Home";

// Tracking components
const TrackOrder = async(() => import("./pages/Tracking/Home"));
const OrderDetails = async(() => import("./pages/Tracking/Details"));

const routes = [
    {
        path: "/",
        element: (
            <HomeLayout />
        ),
        children: [
            {
                path: "",
                element: <TrackOrder />,
            },
            {
              path: "/:id",
              element: <OrderDetails />,
            },
        ]
    }
]

export default routes;