import React, { useState } from "react";
import styled from "@emotion/styled";
import { Outlet } from "react-router-dom";

import { Box, CssBaseline, Paper as MuiPaper } from "@mui/material";

import createTheme from "../theme";
import useTheme from "../hooks/useTheme";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { spacing } from "@mui/system";
import { THEMES } from "../constants";

import Navbar from "../components/navbar/Navbar";
const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const HomeLayout = ({ children }) => {
  const { theme } = useTheme();

    return (
        <Root>
            <CssBaseline />
            <MuiThemeProvider theme={useTheme(theme)}>
                <AppContent>
                    <Navbar />
                    {children}
                    <Outlet />
                </AppContent>
            </MuiThemeProvider>
        </Root>
    );
};

export default HomeLayout;